* {
    font-family: "Red Hat Display", sans-serif;
}

.contact-banner {
    background: url(../../assets/bannerbg2.png) no-repeat center center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30vh;
    text-align: center;
}

.contact-banner h2 {
    color: white;
    font-family: "Red Hat Display", sans-serif;
    position: relative;
    text-shadow: 2px 2px #121212;
    letter-spacing: 2px;
    letter-spacing: 2px;
    margin: 0;
    padding: 2rem 0;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 0.5px solid rgb(245, 245, 245, 0.5);
    font-size: 40px;
}

.contact-container {
    margin: 0;
    padding: 0;
}

.contact-details {
    height: 270px;
    width: 100%;
    background-color: #FFFF00;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.column-container {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-locations {
    height: auto;
    width: 100%;
    background-image: linear-gradient(#FFFF00 0%, #121212 50%);
}

.contact {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 0.25rem;

    margin: 0 30px;
}

.contact-link {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
} 

.contact-icon {
    color: black;
    margin-right: 1rem;
}
.contact-title {
    margin-bottom: 20px;
    text-align: center;
}

.locations-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

.locations-container h4 {
    font-size: 1.2rem;
    color: white;
    text-shadow: 0px 0px 5px black;
}

.locations-carousel img {
    width: 576px;
    height: 384px;
}

.carousel-inner {
    width: 576px;
    height: 384px;
}

.locations-carousel h5 {
    color: white;
    font-weight: bold;
    font-size: 2rem;

    text-shadow: 2px 2px #121212;
    letter-spacing: 2px;
    letter-spacing: 2px;
    margin: 0;
    padding: 1rem 0;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 0.5px solid rgb(245, 245, 245, 0.5);
}



/* Mobile Responsiveness */
@media only screen and (max-width: 600px) {
    .locations-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-content h2 {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .locations-container h4 {
        width: 20rem;
        text-align: center;
    }

    .column-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .locations-carousel img, .carousel-inner {
        width: 360px;
        height: 240px;
    }

}