:root {
  --heading: "Red Hat Display", sans-serif;
  --normal-text: "Red Hat Text", sans-serif;

  --text-color: #001804;
}

/* HOME HERO SECTION STYLES */

.homehero-section {
  position: relative;
  height: 50vh;
  background: url(./assets/bannerbg2.png) no-repeat center center;
}

.homehero-slogan-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.homehero-slogan1 {
  color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  height: 100%;
  font-weight: 1000;
  letter-spacing: 2px;
  font-size: 64px;
  font-family: var(--heading);
  margin-bottom: -1rem;
  padding-bottom: 0;
  text-shadow: 2px 2px #121212;
}
.homehero-slogan2 {
  color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-size: 112px;
  text-shadow: 2px 2px #121212;
}

.home-company-desc-card {
  padding: 1rem;
  margin: 2rem auto;
  margin-bottom: 3rem;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.home-company-desc1,
.home-company-desc2,
.home-company-desc3 {
  margin: 0;
  font-family: var(--heading);
  align-content: center;
  align-items: center;
}

.home-company-desc1 {
  font-weight: 700;
  padding-left: 0;
  font-size: 20px;
}

.home-company-desc2-container,
.home-company-desc3-container {
  margin-top: -2rem;
  border-radius: 0 0 8px 8px;
  color: #f5f5f5;
  background-color: rgba(18, 18, 18, 0.8);
}

.home-company-desc2,
.home-company-desc3 {
  padding: 0.5rem;
  padding-left: 1rem;
  margin-top: 2rem;
  text-align: left;
  letter-spacing: 0.25px;
}

.home-services {
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.home-services h3 {
  font-family: var(--heading);
  margin-bottom: 0;
}

.services-card {
  padding: 0.5rem;
  margin: 0.5rem 0;
  height: 4rem;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: transform 0.2s;
}

.services-card:hover {
  transform: scale(1.05);
}

.our-services {
  background: none;
}
.our-services:hover {
  transform: none;
}

.more-services {
  border-style: none;
  font-family: var(--normal-text);
  font-weight: 500;
  margin-left: auto;
  background: none;
}
.more-services:hover {
  transform: none;
}

.view-more {
  margin-left: auto;
  padding-top: 0.75rem;
}
.view-more-text {
  color: var(--text-color);
}
.view-more-text:hover {
  color: var(--text-color);
}

.arrow-animation {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    transform: rotate(135deg) scale(1.25);
  }
  100% {
    opacity: 1;
  }
}

.icon-container {
  width: 30%;
  height: 40px;
  display: flex;
  justify-content: center;
}

.service-icon {
  width: 40px;
  height: 40px;
}

.bg-icon-container {
  overflow: hidden;
}

.bg-icon {
  position: absolute;
  opacity: 0.1;
  width: 100px;
  top: -1rem;
  left: calc(100% - 30%);
}

.our-services {
  border-style: none;
  align-items: center;
  justify-content: left;
  padding-left: 0;
}

.service-text {
  margin-left: 1rem;
  font-family: var(--normal-text);
  font-weight: 500;
}

/* FEATURED CLIENT STYLES */

.featured-clients {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: url(./components/pages/assets/BGTest/qqquad.svg);
  background-size: contain;
  background-position: center;
}

.featured-clients h3 {
  text-align: center;
  font-family: var(--heading);
  color: var(--text-color);
  margin: 1rem auto;
  background: rgb(255, 255, 255, 0.8);
}

.clients-logo {
  width: 100px;
  height: 100px;
  margin: 1rem;
  padding: 0;
}

.logo-row {
  margin: 0 auto;
  background: rgb(255, 255, 255, 0.3);
  width: auto;
}

.featured-clients .container {
  overflow: hidden;
}

.container .slider {
  animation: slidein 90s linear infinite;
  white-space: nowrap;
  width: 514%;
}

.container .slider .logos {
  width: 100%;
  display: inline-block;
  margin: 0px 0;
}

@keyframes slidein {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

/* OUR PRODUCTS SECTION STYLES */

.home-products-section {
  padding-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  background-color: #f5f5f5;
}

.home-products-section h3 {
  font-family: var(--heading);
  background: rgb(255, 255, 255, 0.5);
}

.home-product-card-container {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 1rem;
}

.home-product-card.kuyaJ {
  height: 275px;
  background-image: url(./components/pages/assets/ProductImages/bbook1.png);
  background-size: contain;
  background-position: center;
  border-radius: 0;
  transition: transform 0.2s;
  border: none;
}

.home-product-card.book {
  height: 275px;
  background-image: url(./components/pages/assets/ProductImages/book2.png);
  background-size: cover;
  background-position: center;
  border-radius: 0;
  background-repeat: no-repeat;
  transition: transform 0.2s;
  border: none;
}

.home-product-card.calendar {
  height: 275px;
  background-image: url(./components/pages/assets/ProductImages/pullup4.png);
  background-size: cover;
  background-position: center;
  border-radius: 0;
  background-repeat: no-repeat;
  transition: transform 0.2s;
  border: none;
}

.home-product-card.poster {
  height: 275px;
  background-image: url(./components/pages/assets/ProductImages/kuyaJ.png);
  background-size: cover;
  background-position: left;
  border-radius: 0;
  background-repeat: no-repeat;
  transition: transform 0.2s;
  border: none;
}

.home-product-card:hover {
  transform: scale(1.05);
}

/* MODE OF PAYMENTS STYLES */

.mop-section {
  margin-bottom: 2rem;
  padding: 1rem;
}

.mop-section-h3-container {
  margin: 0 auto;
}

.mop-section h3 {
  font-family: var(--heading);
  padding: 1rem 0;
  margin: 0;
}

.mop-cards-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mop-card {
  height: 100px;
  border-radius: 0;
  overflow: hidden;
  transition: transform 0.2s;
}
.mop-card:hover {
  transform: scale(1.05);
}

.mop-icon {
  position: absolute;
  bottom: -1rem;
  left: 20%;
  height: 100px;
  width: 100px;
  opacity: 0.8;
}
#ewallet-icon {
  position: absolute;
  left: 45px;
}
#ewallet-number {
  font-size: 32px;
  font-family: var(--heading);
  font-weight: 500;
  margin: 0;
}

.ewallet-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto auto 145px;
}

.mop-name {
  position: absolute;
  color: #f5f5f5;
  background-color: rgba(18, 18, 18, 0.8);
  top: 10%;
  right: 0;
  margin-bottom: 0;
  padding: 0.5rem;
  border-radius: 8px 0 0 8px;
  font-family: var(--heading);
  width: auto;
  letter-spacing: 1px;
}

#ewallet-copy-button {
  border: none;
  border-radius: 0.5rem;
  font-size: 12px;
  margin: 0;
  justify-content: left;
}

/* FOOTER STYLES */

.footer {
  background: #121212;
  color: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: var(--normal-text);
}

.footer-top {
  margin: 1rem auto 2rem auto;
}

#footer-logo {
  width: 200px;
  height: 200px;
}

.footer h6 {
  font-family: var(--heading);
  color: #f5f5f5;
}

.footer h3,
.footer p {
  color: #f5f5f5;
}

.footer-logo-container {
  padding-left: 0;
}

.footer h3 {
  font-family: var(--heading);
}

.copyright {
  margin: auto;
  padding-left: 0;
  color: #f5f5f5;
}

.vector-license-container {
  padding: 0;
  font-size: 12px;
  margin-top: 0.5rem;
  color: rgb(245, 245, 245, 0.75);
}
.vector-license-container a {
  color: rgb(245, 245, 245, 0.75);
}

.vector-license:nth-of-type(2) {
  padding-left: 1rem;
}
.vector-license:nth-of-type(3) {
  padding-left: 1rem;
}

.branches-list {
  list-style: none;
  margin-left: -2rem;
}

.footer-contact {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 0.25rem;
}

.footer-link {
  color: #f5f5f5;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-icon {
  color: #f5f5f5;
  margin-right: 1rem;
}

.footer-nav-links {
  color: #f5f5f5;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

.footer-nav-links:hover {
  text-decoration: underline;
  color: #f5f5f5;
}

.footer-contact a:hover {
  color: #f5f5f5;
}

.footer hr {
  background-color: #f5f5f5;
}

/* About Styles */

.about-hero {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
}

.about-heading {
  background: url(./assets/bannerbg2.png) no-repeat center center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
  text-align: center;
}

.about-title {
  color: white;
  font-family: var(--heading);
  position: relative;
  text-shadow: 2px 2px #121212;
  letter-spacing: 2px;
  letter-spacing: 2px;
  margin: 0;
  padding: 2rem 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 0.5px solid rgb(245, 245, 245, 0.5);
  font-size: 40px;
}

.about-subtitle {
  color: black;
  letter-spacing: 7px;
  font-family: var(--heading);
  padding-bottom: 1rem;
  position: relative;
  margin: 0%;
}

.first-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #59d2fe;
}

.about-des {
  color: black;
  position: relative;
}

.about-description {
  position: relative;
}

#about > div > div > div > div > div > p:nth-child(4) {
  margin-bottom: 0;
}

.mission-vision {
  padding-top: 3rem;
  background-color: #59d2fe;
  padding-bottom: 3rem;
}

.mv-row {
  height: 350px;
}

.our-mission {
  height: 100%;
}

.mission-title {
  position: relative;
  animation: mymovetitle 2s;
  padding-bottom: 1rem;
}

.about-logo {
  width: 200px;
  height: 200px;
}

.about-logo-container {
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vision-title {
  position: relative;
  animation: mymovetitle 2s;
}

.our-mission {
  font-family: var(--normal-text);
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 0%;
  padding-right: 0%;

  color: black;
}

.our-vision {
  font-family: var(--normal-text);
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: black;
}

.about h2,
p,
li {
  font-family: var(--normal-text);
}

.mission-message {
  position: relative;
}

.vision-message {
  position: relative;
}

.second-section {
  background-image: linear-gradient(#59d2fe 0%, #121212 50%);
}

.first-logos {
  text-align: center;
}

/* SERVICES PAGE STYLES */

.services-page-container {
  background-color: #d0fef5;
}

.services-page {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.services-banner {
  background: url(./assets/bannerbg2.png) no-repeat center center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
  text-align: center;
}
.services-banner h2 {
  color: white;
  font-family: var(--heading);
  position: relative;
  text-shadow: 2px 2px #121212;
  letter-spacing: 2px;
  margin: 0;
  padding: 2rem 0;
  letter-spacing: 2px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 0.5px solid rgb(245, 245, 245, 0.5);
  font-size: 40px;
}
.services-subtitle {
  font-family: var(--normal-text);
  color: #121212;
  position: relative;
  text-align: left;
  padding: 1rem 2rem;
  font-weight: 500;
}

.bir-number {
  letter-spacing: 1px;
}

.services-title {
  position: relative;
  align-self: center;
  text-align: center;
  font-family: var(--normal-text);
  width: 30%;
  font-family: var(--heading);
}
.services-title h2 {
  margin: 0;
}
.services-h2row {
  padding-left: 1rem;
  padding-right: 1rem;
}

.services-slider {
  margin: 0 1rem 2rem 1rem;
}

.services-subtitle p {
  margin: 0;
}

.services-page-card-container {
  padding: 1rem;
}

.services-page-card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  position: relative;
  transition: transform 0.2s;
}

.services-page-card:hover {
  transform: scale(1.05);
}

.black-overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.062);
  position: absolute;
}

.services-page-card-title {
  position: absolute;
  color: #f5f5f5;
  background-color: rgba(18, 18, 18, 0.8);
  top: 10%;
  right: 0;
  margin-bottom: 0;
  padding: 0.5rem;
  border-radius: 8px 0 0 8px;
  font-family: var(--heading);
  width: auto;
}

/* PRODUCTS PAGE STYLING */

.products-gradient-bg {
  background: rgb(18, 18, 18);
  background: linear-gradient(
    0deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(241, 252, 253, 1) 31%,
    rgba(199, 238, 255, 1) 74%
  );
}

.products-banner {
  background: url(./assets/bannerbg2.png) no-repeat center center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
  text-align: center;
}
.products-h2row {
  padding-left: 1rem;
  padding-right: 1rem;
}
.products-banner h2 {
  color: white;
  font-family: var(--heading);
  position: relative;
  text-shadow: 2px 2px #121212;
  letter-spacing: 2px;
  letter-spacing: 2px;
  margin: 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 0.5px solid rgb(245, 245, 245, 0.5);
  font-size: 40px;
}

.fp-section {
  padding-bottom: 2rem;
}

.fp-section h3 {
  font-family: var(--heading);
  margin: 0;
  padding: 2rem 0 0.5rem 0;
}

.fprow1 {
  height: auto;
  padding: 0.5rem 0;
}

.fprow2 {
  height: auto;
  padding: 0.5rem 0;
}

.product-card {
  max-height: 50vh;
  overflow: hidden;
  border-radius: 0;
}

.fp-product-img {
  object-fit: contain;
}

.ap-section h3 {
  font-family: var(--heading);
  margin: 0;
  padding: 2rem 0 0.5rem 0;
}

.aproject-card {
  width: 253px;
  height: 300px;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
}

.apsection-container {
  position: relative;
  padding-bottom: 2rem;
}

.aproject-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.2s;
}

.aproduct-name {
  position: absolute;
  color: #f5f5f5;
  background-color: rgba(18, 18, 18, 0.8);
  top: 10%;
  right: 0;
  margin-bottom: 0;
  padding: 0.5rem;
  border-radius: 8px 0 0 8px;
  font-family: var(--heading);
  width: auto;
  transform: translateX(110%);
  transition: transform 0.3s ease-in-out;
  transform: translateX(110%);
}

.aproject-card-hover .aproduct-name {
  transform: translateX(0%);
}

.aproject-card:hover {
  transform: scale(1.05);
}

/* WHITE SPACE HANDLER */

.about-heading,
.first-section,
.mission-vision,
.second-section {
  overflow-y: hidden;
}

/*  MOBILE RESPONSIVENESS */

@media only screen and (max-width: 600px) {
  /* HOME PAGE STYLING - MOBILE */
  .homehero-section {
    height: 30vh;
  }
  .homehero-slogan1 {
    letter-spacing: 1px;
    font-size: 32px;
    padding-bottom: 0.5rem;
  }
  .homehero-slogan2 {
    font-size: 56px;
  }

  .home-company-desc-card {
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .home-company-desc1 {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  .home-company-desc2-container,
  .home-company-desc3-container {
    margin-top: 0;
    border-radius: 0 8px 8px 0;
    margin-left: -0.5rem;
  }
  .home-company-desc2,
  .home-company-desc3 {
    margin-top: 0;
  }
  #our-services-col {
    padding-right: 0.5rem;
  }
  .our-services {
    padding: 0;
  }
  .home-services h3 {
    font-size: 24px;
  }
  .more-services {
    padding: 0;
  }
  .view-more {
    padding-top: 0.5rem;
    font-size: 16px;
  }
  .services-card {
    margin: 0.25rem auto;
  }
  .bg-icon {
    opacity: 0.08;
  }

  .home-products-section h3 {
    font-size: 24px;
    margin: 0;
  }
  .home-product-card.kuyaJ {
    height: 200px;
    margin-bottom: 0.5rem;
  }
  .home-product-card.book,
  .home-product-card.calendar {
    height: 200px;
    margin-bottom: 0.5rem;
  }
  .home-product-card.poster {
    height: 200px;
  }

  .mop-section {
    margin-bottom: 1rem;
  }
  .mop-section h3 {
    font-size: 24px;
  }
  .mop-cards-container {
    margin-top: -1rem;
  }
  .mop-card {
    margin-bottom: 0.5rem;
  }

  #mop-card-cash {
    padding-right: 0.25rem;
  }
  #mop-card-check {
    padding-left: 0.25rem;
  }
  .mop-icon {
    height: 75px;
    width: 75px;
    left: 10%;
  }
  #ewallet-icon {
    left: 20px;
  }
  .ewallet-flex {
    margin: 0;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  #ewallet-number {
    font-size: 21px;
  }
  .mop-name {
    top: 5%;
  }

  .featured-clients h3 {
    font-size: 24px;
  }
  .container .slider {
    width: 2133%;
  }

  /* ABOUT PAGE STYLING - MOBILE */

  /* CONTACT PAGE STYLING - MOBILE*/

  /* PRODUCTS PAGE STYLING - MOBILE*/
  .products-banner {
    height: 20vh;
    width: 100%;
  }
  .products-banner h2 {
    padding: 0;
  }
  .fp-section h3 {
    font-size: 22px;
  }
  .fprow1 {
    padding-top: 0;
  }
  .fprow1 .col-6 {
    max-width: 50%;
  }
  .fprow1 .col-6:nth-child(1) {
    padding-right: 0;
  }
  .fprow1 .col-6:nth-child(2) {
    padding-left: 0;
  }
  .fprow2 {
    margin: 0 0;
    padding-top: 0;
  }
  .fprow2 .col-4 {
    padding: 0;
  }

  .ap-section h3 {
    font-size: 22px;
    padding-bottom: 0;
  }
  .aproject-card {
    width: auto;
    height: auto;
  }
  .aproject-card p {
    font-size: 14px;
  }

  #root
    > div
    > div.products-gradient-bg
    > section.fp-section
    > div
    > div:nth-child(6)
    > div.col-8 {
    padding: 0;
  }

  /* SERVICES PAGE STYLING - MOBILE */
  .services-banner {
    height: 20vh;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .services-banner h2 {
    padding: 0;
  }
  .services-subtitle {
    padding: 0;
    margin: 0;
    width: inherit;
    margin: 0 auto;
    padding-bottom: 0;
  }
  .services-subtitle p {
    margin: 0;
    padding-bottom: 1rem;
    text-align: justify;
  }
  .bir-number {
    letter-spacing: 0;
    font-size: 20px;
  }
  .services-page-card-container {
    padding: 0.5rem;
  }
  .services-page-card-title {
    font-size: 16px;
    text-align: right;
    width: auto;
    font-size: 14px;
  }
  #root
    > div
    > div.services-page.container
    > div
    > div.slick-list
    > div
    > div:nth-child(6)
    > div
    > div
    > div
    > div
    > div.services-page-card-title.card-title.h5 {
    margin-left: 3.5rem;
  }
  .services-slider {
    margin: 0 0 2rem 0;
  }
  .services-page {
    overflow-x: hidden;
  }

  /* FOOTER STYLES - MOBILE */
  .footer {
    padding: 1rem 1rem 1rem 1rem;
  }
  .footer p {
    font-size: 14px;
  }
  .footer-mainrow {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .footer h3 {
    font-size: 20px;
  }
  .footer-logo-container {
    flex-shrink: 1;
    max-width: fit-content;
  }
  #footer-logo {
    width: 100px;
    height: 100px;
  }
  .contact-us-container {
    order: 1;
    padding: 0;
    margin-top: 1rem;
  }
  .footer-address {
    padding: 0;
    display: flex;
    max-width: max-content;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    flex: 0 0 calc(100% - 116px);
  }
  .footer-address h6 {
    margin-bottom: 2px;
  }
  .footer-address p {
    margin-bottom: 0;
  }
  .footer-contact {
    margin-left: 0;
    margin-right: 0;
  }
  .footer-contact a {
    padding: 0;
    font-size: 15px;
  }
  .footer-address li {
    margin-bottom: 0.1rem;
  }
  .footer hr {
    margin: 0.5rem -1rem;
    background-color: #f5f5f5;
  }
  .footer-copyright {
    padding: 0;
  }
  .footer-copyright div {
    display: block;
  }
  .copyright {
    padding-top: 0.25rem;
    padding-left: 1rem;
    font-size: 13px;
    text-align: center;
  }
  .footer .hide-in-mobile {
    display: none;
  }
  .footer-copyright .nav-item {
    display: inline-flex;
    font-size: small;
  }
  .footer-copyright .nav-item .footer-nav-links {
    padding: 4px;
    padding-bottom: 0;
  }
  .footer-nav-links-parent {
    text-align: center;
  }
  .footer-copyright .row:nth-of-type(2) {
    text-align: center;
  }
  .vector-license-container {
    font-size: 10px;
  }
  .vector-license:nth-of-type(1) {
    padding-left: 4px;
  }
}
