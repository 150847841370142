.active {
  background-color: none;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #121212;
  top: 0;
  padding: 0 75px;
  box-sizing: border-box;
}

nav .title {
  margin: 1rem;
  color: #002006;
  font-family: Red Hat Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #002006;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
  background-color: #f5f5f5;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: white;
  border-radius: 0.2rem;
}

@media (max-width: 480px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}

/*Logo */
#navbar-logo {
  width: 50px;
  margin-right: 20px;
}


#nav-title {
  color: #f5f5f5;
  font-family: "Red Hat Display", sans-serif;
  letter-spacing: 1px;
}

.nav-link {
  color: #f5f5f5;
}

.active:not(.nav-logo) {
  background-color: #f5f5f5;
}

.navbar-toggler {
  background-color: #f5f5f5;
}